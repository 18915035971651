@charset "UTF-8";
$colorBrown: #42210b;
$iconFacebook: "\e900";
$iconMail: "\e902";
$iconPin: "\e903";
$iconArrow: "\e901";
$iconTel: "\e905";
$iconBlog: "\e904";

@mixin icon {
	font-family: "icomoon" !important;
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	line-height: 1;

	text-transform: none;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// #clearfix
@mixin clearfix {
	&:after {
		content: ".";
		display: block;
		height: 0;
		font-size: 0;

		visibility: hidden;
		clear: both;
	}
}

// sp
@mixin sp {
	@media (max-width: 425px) {
		@content;
	}
}
