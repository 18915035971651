@charset "UTF-8";

@import "mixin";

.m-inner {
  max-width: 1040px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  box-sizing: border-box;
  @include sp {
    padding: 0 15px;
  }
}

.m-img_link {
  position: relative;
  img:last-child {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    transition: .15s;
  }
  &:hover {
    img {
      opacity: 1;
    }
  }
  &.current {
    img {
      opacity: 1;
    }
  }
  @include sp {
    &:hover {
      img:last-child {
        opacity: 0;
      }
    }
  }
}

.m-headline {
  display: block;
  margin: 0 auto 30px;
  position: relative;
  max-width: 1000px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: calc(50% - 1px);
    width: 100%;
    height: 2px;
    background: url('../../images/sub/headline-border.png') repeat-x;
  }
  @include sp {
    margin-bottom: 15px;
  }
  &--img {
    display: block;
    position: relative;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    background-color: #fafaf0;
    padding: 0 15px;
    @include sp {
      height: auto;
      padding: 0 5px;
    }
  }
}

.m-news_list {
  height: 230px;
  padding: 15px 30px;
  background-color: #fff;
  border-radius: 15px;
  box-sizing: border-box;
  overflow: auto;
  border: 2px solid $colorBrown;
  @include sp {
    padding: 15px;
  }
  &-archive {
    border: 0;
    background: transparent;
    background: url(../../../images/sub/sub-cms-list-border.png) repeat-x top left;
    padding: 6px 0 0 0;
    height: auto;
    @include sp {
      padding-top: 20px;
    }
  }
  &--item {
    position: relative;
    background: url("../../images/common/border.png") repeat-x bottom left;
    &:last-child {
      background: none;
    }
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 93%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      background-color: #fdeacc;
      border-radius: 10px;

      transition: .3s cubic-bezier(.175, .885, .32, 1.275);
      transform: scale(.85, .85);
    }
    &:hover {
      &:before {
        opacity: 1;

        transform: scale(1, 0.9);
      }
    }
    @include sp {
      margin-bottom: 15px;
      padding-bottom: 15px;
      &:before {
        display: none;
      }
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
      &:hover {
        &:before {
          display: none;
        }
      }
    }
    &-archive {
      background: none;
      border-bottom: 1px dashed #ccc;
    }
  }
  &--link {
    display: flex;
    align-items: center;
    padding: 15px 15px 15px;
    position: relative;
    color: $colorBrown;
    text-decoration: none;

    @include sp {
      flex-wrap: wrap;
      padding: 0;
    }
  }
  &--time {
    display: block;
    margin: 0 15px 0 0;
    font-size: 14px;

    @include sp {
      width: 70px;
      margin: 0 15px 5px 0;
    }
  }
  &--cat {
    display: block;
    width: 80px;
    height: 20px;
    margin: 0 20px 0 0;
    font-size: 12px;
    line-height: 20px;
    background-color: red;
    color: #fff;
    text-align: center;
    border-radius: 10px;

    @include sp {
      margin-bottom: 5px;
    }
    &-description {
      background-color: #a989c0;
    }
    &-event {
      background-color: #f5911e;
    }
    &-news {
      background-color: #7ac743;
    }
    &-kindergarten {
      background-color: #f37a7a;
    }
    &-recruit {
      background-color: #63b7f4;
    }
    &-attention {
      background-color: #eb1c24;
    }
    &-blog {
      background-color: #c49a6d;
    }
  }
  &--txt {
    flex: 1;
    font-size: 14px;
    line-height: 1.5;
    @include sp {
      flex: auto;
    }
  }
}